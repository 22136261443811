export const bannerData = {
  bannerText: (
    <p>
      Happy New Year! Get 25% Off Redact Premium and Ultimate{" "}
      <span className="underline">with code NY25.</span>
    </p>
  ),
  bannerTextMobile: (
    <p>
      Happy New Year! Get 25% Off Redact Premium and Ultimate{" "}
      <span className="underline">with code NY25.</span>
    </p>
  ),
  path: "/new-year", //if its Redact.dev just use slash, if its a complete url put it in this format "https://www.stripe.com/redact"
  isActive: false,
  showsExpiration: true,
  showsExpirationMobile: true,
  expirationDate: "2025-01-03T08:00:00Z", //YYYY-MM-DD T HH:MM:SS UTC
};
