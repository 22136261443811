import { DateTime } from "luxon";
import Link from "next/link";
import { useEffect, useState } from "react";
import { bannerData } from "src/data/bannerData";
import useWindowSize from "../../hooks/useWindowSize";
import Image from "next/image";
import { usePathname } from "next/navigation";

const Banner = ({}) => {
  const expirationDate = DateTime.fromISO(bannerData.expirationDate);
  const [countdown, setCountdown] = useState(expirationDate.diffNow());
  const pathname = usePathname();

  const [activeBanner, setActiveBanner] = useState(true);

  const handleBannerActivity = () => {
    setActiveBanner(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveBanner(bannerData.isActive && expirationDate > DateTime.now());
    }
    const intervalId = setInterval(() => {
      if (expirationDate <= DateTime.now()) {
        setActiveBanner(false);
        clearInterval(intervalId);
      }
      setCountdown(expirationDate.diffNow());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { width } = useWindowSize();

  if (!bannerData.isActive) {
    return null;
  }

  if (pathname === bannerData.path) {
    return null;
  }

  return (
    <>
      <div
        className={`
      fixed opacity-95 rounded-md w-full bottom-1 shadow-custom bg-brand-purple text-brand-white z-[100]
      sm:top-0 sm:rounded-none sm:opacity-90 sm:h-12
      ${activeBanner ? "flex" : "hidden"} ${bannerData.showsExpirationMobile ? "h-32" : "h-16"}`}
      >
        <a onClick={handleBannerActivity}>
          <Image
            className={`
          absolute mt-4 cursor-pointer
          sm:ml-[95%]
          ${bannerData.showsExpirationMobile ? "ml-[90%]" : "ml-[92%]"}
          `}
            src="/images/svgs/exit_banner.svg"
            alt="mobile preview"
            height={16}
            width={16}
          />
        </a>
        <Link href={bannerData.path} passHref legacyBehavior style={{ textDecoration: "none" }}>
          <a
            className={`
          flex flex-col items-start justify-start w-full h-12 text-base text-brand-white no-underline hover:no-underline
          sm:text-lg sm:flex-row sm:items-center sm:justify-center
          `}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Image
                className={`
              mt-3 ml-3
              sm:mr-1 sm:mt-0 sm:ml-0
              ${bannerData.showsExpiration ? "sm:flex" : "sm:hidden"} ${bannerData.showsExpirationMobile ? "flex" : "hidden"};
              `}
                src="/images/svgs/clock.svg"
                alt="mobile preview"
                height={20}
                width={20}
              />
              <p
                className={`
              text-base mt-5 ml-3 h-0
              sm:text-xl sm:mt-0
              ${bannerData.showsExpiration ? "sm:flex" : "sm:hidden"} ${bannerData.showsExpirationMobile ? "flex" : "hidden"}
              `}
                suppressHydrationWarning
              >
                Expires in {countdown?.toFormat("dd':'hh':'mm':'ss'")}
              </p>
              <div
                className="
             flex mt-5 ml-2 h-[23px] w-[80px] items-center justify-center rounded-xl bg-yellow-400 font-bold text-black sm:hidden
            "
              >
                25% OFF
              </div>
            </div>
            <div
              className={`
            flex text-base ml-5 w-[90%] font-semibold items-center justify-center bottom-12
            sm:text-xl sm:w-[80%]
            ${bannerData.showsExpirationMobile ? "mt-[-0.5rem]" : "mt-[-0.1rem]"};
            `}
            >
              <div
                className="
                hidden mt-0 mr-5 h-[28px] w-[100px] items-center justify-center rounded-xl bg-yellow-400 font-bold text-black sm:flex
              "
              >
                25% OFF
              </div>
              {width > 1140 ? bannerData.bannerText : bannerData.bannerTextMobile}
            </div>
          </a>
        </Link>
        <span className="icon-[openmoji--flag-russia] invisible absolute"></span>
        <span className="icon-[openmoji--flag-turkey] invisible absolute"></span>
      </div>
      {activeBanner && <div style={{ marginTop: "25px" }} />}
    </>
  );
};

export default Banner;
